import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css'],
})
export class CookiesComponent implements OnInit {
  cookieAccepted: boolean = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    const cookieAcceptedValue = this.cookieService.get('cookie_accepted');
    if (cookieAcceptedValue === 'true') {
      this.cookieAccepted = true;
    } else if (cookieAcceptedValue === 'false') {
      this.cookieAccepted = true;
    }
  }

  acceptCookies(): void {
    this.cookieService.set('cookie_accepted', 'true', 365); // Expire dans 365 jours
    const cookieAcceptedValue = this.cookieService.get('cookie_accepted');

    this.cookieAccepted = true;
    this.hideCookiePopup();
  }

  private showCookiePopup(): void {
    const popup = document.getElementById('cookie-popup');
    if (popup) {
      popup.style.display = 'block';
    }
  }

  hideCookiePopup(): void {
    const popup = document.getElementById('cookie-popup');
    if (popup) {
      popup.style.display = 'none';
    }
  }
  refusCookies(): void {
    this.cookieService.set('cookie_accepted', 'false', 365); // Expire dans 365 jours
    const cookieAcceptedValue = this.cookieService.get('cookie_accepted');

    this.cookieAccepted = true;
    this.hideCookiePopup();
  }
}
