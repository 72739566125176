import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { API_CONFIG,_getURL } from 'config/API_CONFIG';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MedecinService } from '../../services/medecin.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inscription-medecin',
  templateUrl: './inscription-medecin.component.html',
  styleUrls: ['./inscription-medecin.component.css', ]
})
export class InscriptionMedecinComponent implements OnInit {
  specialiites = ["Anatomie Pathologique", "Anesthesie & Anesthesie Reanimation", "Bacteriologie & Microbiologie",
    "Biochimie", "Biologie Clinique", "Biophysique Et Medecine Nucleaire", "Cardiologie", "Chirurgie Cancérologie",
    "Chirurgie Cardio-Vasculaire & Peripherique", "Chirurgie Generale", "Chirurgie Orthopedique", "Chirurgie Pediatrique",
    "Chirurgie Plastique & Reparatrice", "Dermatologie", "Endocrinologie", "Gastro-Enterologie", "Gynecologie-Obstetrique",
    "Hematologie Biologique", "Hematologie Clinique", "Histo-Embryologie", "Immunologie & Virologie", "Maladies Infectieuses",
    "Medecin Dentiste", "Medecine Cancérologique", "Medecine D'Urgence", "Medecine De Famille", "Medecine Du Travail", "Medecine Generale",
    "Medecine Interne", "Medecine Physique", "Nephrologie", "Neuro-Chirurgie", "Neurologie", "Nutrition", "O.R.L.", "Ophtalmologie",
    "Orthopedie Dento Faciale", "Pediatrie", "Physiologie & Exploration Fonctionnelle", "Physiotherapie", "Pneumo-Phthisiologie", "Psychiatrie",
    "Psychiatrie Infantile", "Radiologie (Diagnostic)", "Radiotherapie", "Reanimation Medicale", "Rhumatologie", "Stomatologie Et Chirurgie Maxillo-Faciale",
    "Urologie"
  ]
  registerForm: FormGroup;
  submitted: boolean = false;

  country = 'BE'
  countryCode = "32";
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(private router:Router,private http:HttpClient,private formBuilder: FormBuilder,private medecinService: MedecinService,
    private recaptchaV3Service: ReCaptchaV3Service, public iziToast: Ng2IzitoastService, ) {}

    ngOnInit(): void {
      this.registerForm = this.formBuilder.group(
        {
          nom: [
            '',
            [
              Validators.required,
              Validators.maxLength(25),
              Validators.minLength(3),
            ],
          ],
          prenom: [
            '',
            [
              Validators.required,
              Validators.maxLength(25),
              Validators.minLength(3),
            ],
          ],
          email: ['', [Validators.required, Validators.email]],
          password: ['', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(50),
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')
          ]],
          confirmationPassword: ['', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(50),
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')
          ]],    
          tel: ['', Validators.required],
          specialite: ['', Validators.required],
          numInscription: [
            '',
            [Validators.required, Validators.minLength(4),Validators.maxLength(11)],
          ],
          acceptTerms: [false, Validators.requiredTrue],
        },
        {
          validator: MustMatch('password', 'confirmationPassword'),
        }
      );
    }
  get f() { return this.registerForm.controls; }
  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
     if(!this.registerForm.value.tel){
        return;
     }
    this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.http.post(_getURL(API_CONFIG.recaptchaverification), { token: token })
          .subscribe((response: any) => {
            if (response && response.success && response.score >= 0.5) {
              this.suivreRegister()
              return true;
            } else {
              this.iziToast.show({
                message: "Erreur de recaptcha",
                messageColor: '#800f2f',
                titleColor: '#800f2f',
                progressBarColor: '#c9184a',
                icon: 'bi-exclamation-diamond',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#ff8fa3',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
                // overlay: true,
                // overlayClose: true,
              });
              return false;
            }
            // Handle the verification response from the backend
          });
      }
    }, error => {
      this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      return false;
    });
  }
  suivreRegister() {
    this.medecinService.verifyNumInscription(this.registerForm.value).subscribe((data: any) => {
      if (data.success) {
        this.iziToast.show({
          message: "Le numéro d'inscription est déjà utilisé",
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          icon: 'bi-exclamation-diamond',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          // overlay: true,
          // overlayClose: true,
        });
        return false;
      } else {
        this.registerForm.value.diplome = this.registerForm.value.specialite
        this.medecinService.registerMedecin(this.registerForm.value)
          .subscribe(medecin => {
            this.iziToast.show({
          message: "Votre demande d'inscription a été bien envoyée!",
          messageColor: '#21726B',
          progressBarColor: '#21726B',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#e7f5f5',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
        })
          this.router.navigate(['/']);
            return
          });
      }
    })
  }

  ngAfterViewInit(): void {
    // Faire défiler la fenêtre vers le haut au chargement du composant
    window.scrollTo(0, 0);
  }

  openPDF() {
    const pdfUrl = `${window.location.origin}/assets/medivisto-conditions-generales-utilisation.pdf`;
    window.open(pdfUrl, '_blank'); 
  }
}
