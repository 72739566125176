import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as bootstrap from 'bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  verif = 'background-transparent';
  constructor(private router: Router) {}
  ngOnInit(): void {
    this.router.url !== '/'
      ? (this.verif = '')
      : (this.verif = 'background-transparent ');
  }

  ngAfterViewInit(): void {
    const navbarShrink = () => {
      const navbarCollapsible = document.body.querySelector('#mainNav');
      if (!navbarCollapsible) {
        return;
      }
      if (window.scrollY === 0) {
        navbarCollapsible.classList.remove('navbar-shrink');
      } else {
        navbarCollapsible.classList.add('navbar-shrink');
      }
    };

    navbarShrink();

    document.addEventListener('scroll', navbarShrink);

    const mainNav = document.body.querySelector('#mainNav');
    if (mainNav) {
      new bootstrap.ScrollSpy(document.body, {
        target: '#mainNav',
        rootMargin: '0px 0px -40%',
      });
    }

    const responsiveNavItems = document.querySelectorAll(
      '#navbarResponsive .nav-link'
    );

    responsiveNavItems.forEach((responsiveNavItem: HTMLElement) => {
      responsiveNavItem.addEventListener('click', () => {
        const navbarCollapse = document.querySelector(
          '.navbar-collapse'
        ) as HTMLElement;
        const bootstrapCollapse = new bootstrap.Collapse(navbarCollapse);
        bootstrapCollapse.hide();
      });
    });
  }
}
