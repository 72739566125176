export const API_CONFIG = {
  apiPathBase: '/',
 //apiPathBase: 'http://localhost:3012/',
 article:'api/article/',
 contact:'api/contact/',
 uploadArticle:'data/files/articles',
 recaptchaverification:'api/verify-recaptcha',
 medecin:'api/medecin',
 pharmacie:'api/pharmacie',
 patient:'api/users',
 authenticateMedecin:'api/authenticate/medecin/new/version',
 authenticateMedecinCode:'api/authenticate/medecin/new/version/code'

};
export function _getURL(serviceName){
 return API_CONFIG.apiPathBase + serviceName;
}

// export const ophtalmoLink = "http://localhost:4200"
// export const othersLink = "http://localhost:4200"

export const ophtalmoLink = "https://ophtalmo-test.medivisto.com"
export const othersLink = "https://connect-test.medivisto.com"
