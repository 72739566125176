import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MedecinService } from 'src/app/services/medecin.service';

@Component({
  selector: 'app-pharmacie',
  templateUrl: './pharmacie.component.html',
  styleUrls: ['./pharmacie.component.css']
})
export class PharmacieComponent implements OnInit {

  pharmacies: any;
  nom: any;
  typePharmacie: any;
  villePharmacie: any;
  currentPage: number = 1;
  totalPages: number = 0;
  totalPagesArray: number[] = [];
  villesTunisiennes = [
    "Tunis", "Ariana", "Ben Arous", "Manouba", "Nabeul", "Zaghouan",
    "Bizerte", "Béja", "Jendouba", "Le Kef", "Siliana", "Kairouan",
    "Kasserine", "Sidi Bouzid", "Sousse", "Monastir", "Mahdia", "Sfax",
    "Gabès", "Médenine", "Tataouine", "Gafsa", "Tozeur", "Kebili"
  ];
  constructor(private medecinService: MedecinService, private route: ActivatedRoute,private spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 2000); // 2 seconds
    this.route.queryParams.subscribe(params => {
      this.nom = params['nom'];
      this.typePharmacie = params['specialite'];
      this.villePharmacie = params['localisation'];


      if(this.typePharmacie == "Nuit"){
        this.typePharmacie = "PHARMACIEN D'OFFICINE DE NUIT"
      }
      else{
        this.typePharmacie = "PHARMACIEN D'OFFICINE DE JOUR"
      }

      if(this.villePharmacie && this.villePharmacie != undefined && this.villePharmacie.indexOf("é") > -1 ){
        this.villePharmacie = this.villePharmacie.replace(/é/g, 'e');}
      if(this.villePharmacie && this.villePharmacie != undefined && this.villePharmacie.indexOf("è") > -1 ){
        this.villePharmacie = this.villePharmacie.replace(/è/g, 'e');}  
      
      this.searchPharmacie();
    });
  }

  searchPharmacie() {
    if(this.nom && this.nom.length > 50){
      this.pharmacies = []
      return false;
    }
    if(this.typePharmacie && this.typePharmacie != "PHARMACIEN D'OFFICINE DE NUIT" && this.typePharmacie != "PHARMACIEN D'OFFICINE DE JOUR"){
      this.pharmacies = []
      return false;
    }
    if(this.villePharmacie && this.villesTunisiennes.indexOf(this.villePharmacie) === -1){
      this.pharmacies = []
      return false;
    }
    this.medecinService.searchPharmacie(this.nom, this.typePharmacie, this.villePharmacie, this.currentPage)
        .subscribe(
            (data: any) => {
                this.pharmacies = data.pharmacies;
                this.totalPages = data.totalPages;
                this.totalPagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
            },
            (error) => {
                console.error(error);
            }
        );
}
  ngAfterViewInit(): void {
    // Faire défiler la fenêtre vers le haut au chargement du composant
    window.scrollTo(0, 0);
  }

  nextPage(): void {
    window.scrollTo(0, 0);
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.searchPharmacie();
    }
  }

  previousPage(): void {
    window.scrollTo(0, 0);
    if (this.currentPage > 1) {
      this.currentPage--;
      this.searchPharmacie();
    }
  }
  goToPage(page: number): void {
    window.scrollTo(0, 0);

    this.currentPage = page;
    this.searchPharmacie();
  }
  getPageNumbers(): number[] {
    const numButtonsToShow = 0; // Nombre de boutons de page à afficher
    let start = 1;
    let end = this.totalPages;

    if (this.totalPages > numButtonsToShow) {
        if (this.currentPage <= Math.ceil(numButtonsToShow / 2)) {
            start = 1;
            end = numButtonsToShow;
        } else if (this.currentPage >= this.totalPages - Math.floor(numButtonsToShow / 2)) {
            start = this.totalPages - numButtonsToShow + 1;
            end = this.totalPages;
        } else {
            start = this.currentPage - Math.floor(numButtonsToShow / 2);
            end = this.currentPage + Math.floor(numButtonsToShow / 2);
        }
    }

    // Vérifier si la première page est réellement la première dans la liste
    if (start > 1) {
        start = Math.max(1, start - 1); // Réduire d'un pour inclure la première page
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

getDirections(pharmacie: any): void {
  // Récupérer l'adresse de la pharmacie
  const adressePharmacie = `${pharmacie.adresse}, ${pharmacie.localite}, ${pharmacie.gouvernorat}`;

  // Vérifier si le navigateur prend en charge la géolocalisation
  if (navigator.geolocation) {
    // Demander la position de l'utilisateur
    navigator.geolocation.getCurrentPosition((position) => {
      // Récupérer les coordonnées géographiques de l'utilisateur
      const userLatitude = position.coords.latitude;
      const userLongitude = position.coords.longitude;

      // Construire l'URL pour les directions
      const directionsURL = `https://www.google.com/maps/dir/${userLatitude},${userLongitude}/${encodeURIComponent(adressePharmacie)}`;

      // Ouvrir les directions dans un nouvel onglet
      window.open(directionsURL, '_blank');
    }, (error) => {
      console.error('Erreur de géolocalisation:', error);
      // En cas d'erreur, ouvrir les directions dans un nouvel onglet sans la position actuelle de l'utilisateur
      const directionsURL = `https://www.google.com/maps/dir//${encodeURIComponent(adressePharmacie)}`;
      window.open(directionsURL, '_blank');
    });
  } else {
    // Si la géolocalisation n'est pas prise en charge, ouvrir les directions dans un nouvel onglet sans la position actuelle de l'utilisateur
    const directionsURL = `https://www.google.com/maps/dir//${encodeURIComponent(adressePharmacie)}`;
    window.open(directionsURL, '_blank');
  }
}

}
