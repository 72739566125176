import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedecinService } from '../../services/medecin.service';
import { Router } from '@angular/router';
import { API_CONFIG, _getURL, ophtalmoLink, othersLink } from 'config/API_CONFIG';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { Ng2IzitoastService } from 'ng2-izitoast';
@Component({
  selector: 'app-mot-de-passe-oublie-medecin',
  templateUrl: './mot-de-passe-oublie-medecin.component.html',
  styleUrls: ['./mot-de-passe-oublie-medecin.component.css']
})
export class MotDePasseOublieMedecinComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean = false;
  isValid = true;
  constructor(private formBuilder: FormBuilder, private medecinService: MedecinService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service, public iziToast: Ng2IzitoastService,
    private http: HttpClient) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.maxLength(100)]],
      numInscription: ['',[Validators.required, Validators.minLength(4),Validators.maxLength(11)],],
    });
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.http.post(_getURL(API_CONFIG.recaptchaverification), { token: token })
          .subscribe((response: any) => {
            if (response && response.success && response.score >= 0.5) {
              this.suivreResetPassword()
              return true;
            } else {
              this.isValid = false
              this.iziToast.show({
                message: "Erreur de recaptcha",
                messageColor: '#800f2f',
                titleColor: '#800f2f',
                progressBarColor: '#c9184a',
                icon: 'bi-exclamation-diamond',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#ff8fa3',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
                // overlay: true,
                // overlayClose: true,
              });
              setTimeout(() => {
                  this.isValid = true
                },
                5000);
              return false;
            }
            // Handle the verification response from the backend
          });

      }
    }, error => {
      this.isValid = false
      this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    });
  }
  suivreResetPassword() {
    if (this.registerForm.value.email == undefined || this.registerForm.value.email === '') {
      this.isValid = false
      this.iziToast.show({
        message: "Ajoutez votre Email",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    }
    this.medecinService.recoverPassword(this.registerForm.value).subscribe((data: any) => {
        if (data && data.success) {
          this.iziToast.show({
            message: "Un e-mail de réinitialisation de mot de passe a été envoyé. Veuillez vérifier votre boîte de réception.",
            messageColor: '#21726B',
            progressBarColor: '#21726B',
            imageWidth: 45,
            position: 'topRight',
            timeout: 8000,
            backgroundColor: '#e7f5f5',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
          })
          this.router.navigate(['']);
          return true;
        } else {
          this.isValid = false
          let msg = ""
          if (data) {
            msg = data.msg
            this.iziToast.show({
              message: msg,
              messageColor: '#800f2f',
              titleColor: '#800f2f',
              progressBarColor: '#c9184a',
              icon: 'bi-exclamation-diamond',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              // overlay: true,
              // overlayClose: true,
            });
            setTimeout(() => {
                this.isValid = true
                this.router.navigate(['mot-de-passe-oublie/medecin']);
              },
              5000);
          }
        }
      },
      err => {
        return false;
      });



  }
}
