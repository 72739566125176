import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedecinService } from '../../services/medecin.service';
import { Router } from '@angular/router';
import { API_CONFIG, _getURL,ophtalmoLink,othersLink } from 'config/API_CONFIG';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { Ng2IzitoastService } from 'ng2-izitoast';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.css']
})
export class ConnexionComponent implements OnInit {
  registerForm: FormGroup;
  medecinForm: FormGroup;
  submitted: boolean = false;
  isValid = true;
  counterClick = 0
  isMedecin: boolean = true;
  isPatient: boolean = false;
  showCodeInterface = false;
  medecinFormCode:FormGroup;
  constructor(private formBuilder: FormBuilder, private medecinService: MedecinService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service, public iziToast: Ng2IzitoastService,
    private http: HttpClient) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.medecinForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.maxLength(100)]],
      password: ['', [Validators.required,Validators.maxLength(50)]],
      // numInscription: ['', [Validators.required, Validators.pattern(/^\d{11}$/)]],
      numInscription: ['',[Validators.required,Validators.minLength(4) ,Validators.maxLength(11)],],
    });
    this.medecinFormCode = this.formBuilder.group({
      codeValidation: ['',[Validators.required, Validators.maxLength(11)],],
    });
  }
  get f() { return this.registerForm.controls; }
  get f2() { return this.medecinForm.controls; }
  get f3() { return this.medecinFormCode.controls; }


  onMedecinSubmit() {
    this.submitted = true;
    if (this.medecinForm.invalid) {
      return;
    }
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.http.post(_getURL(API_CONFIG.recaptchaverification), { token: token })
          .subscribe((response: any) => {
            if (response && response.success && response.score >= 0.5) {
              this.suivreLoginMedecin()
              return true;
            } else {
              this.isValid = false
              this.iziToast.show({
                message: "Erreur de recaptcha",
                messageColor: '#800f2f',
                titleColor: '#800f2f',
                progressBarColor: '#c9184a',
                icon: 'bi-exclamation-diamond',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#ff8fa3',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
                // overlay: true,
                // overlayClose: true,
              });
              setTimeout(() => {
                  this.isValid = true
                },
                5000);
              return false;
            }
            // Handle the verification response from the backend
          });

      }
    }, error => {
      this.isValid = false
      this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    });
  }

  suivreLoginMedecin() {
    const medecin = {
      email: this.medecinForm.value.email,
      password: this.medecinForm.value.password,
      numInscription: this.medecinForm.value.numInscription
    }
    if (medecin.email == undefined || medecin.email === '') {
      this.isValid = false
      this.iziToast.show({
        message: "Ajoutez votre Email",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    }
    if (medecin.numInscription == undefined || medecin.numInscription === '') {
      this.isValid = false
      this.iziToast.show({
        message: "Ajoutez votre numéro d\'inscription",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    }
    
    if (medecin.password == undefined) {
      this.isValid = false
      this.iziToast.show({
        message: "Ajouter votre mot de passe",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    } else {
      this.counterClick = this.counterClick + 1
      this.showCodeInterface = false;
      this.medecinService.authenticateMedecinNewVersion(medecin).subscribe((data: any) => {
          if (data && data.success && data.token && data.token != null && data.token != "null" && data.token != undefined && data.token != "undefined" && data.token.indexOf("Bearer") > -1 && data.type && data.type === 'tokenAuth') {
            data.token = data.token.replace("Bearer","redirection")
            let idclient =Array(15).fill(null).map(() => Math.random().toString(36).substr(2)).join('')
            var externalLink = othersLink+'/redirect';
            if(data.spec=== "Ophtalmologiste" || data.spec=== "Ophtalmologie"){
              externalLink=ophtalmoLink+'/redirect';
            }
            const queryParams = {
              redirect: data.token,
              clientID: idclient,
              redirect_url: "https://www.medivisto.com",
             };
            const urlWithParams = this.constructExternalLinkWithParams(externalLink, queryParams);
            window.location.href = urlWithParams;
            return true;
          } else if(data && data.success && data.type && data.type === "codeAuth") {
            this.showCodeInterface = true;
            this.iziToast.show({
              message: 'Le code de vérification pour la connexion sécurisée a bien été envoyé sur votre compte e-mail.',
              messageColor: '#21726B',
              progressBarColor: '#21726B',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#e7f5f5',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
            });
            this.submitted = false;
            return true;
          } else {
            this.isValid = false
            let msg = ""
            if (data) {
              msg = data.msg
              this.iziToast.show({
                message: msg,
                messageColor: '#800f2f',
                titleColor: '#800f2f',
                progressBarColor: '#c9184a',
                icon: 'bi-exclamation-diamond',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#ff8fa3',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
                // overlay: true,
                // overlayClose: true,
              });
              setTimeout(() => {
                  this.isValid = true
                  this.router.navigate(['connexion']);
                },
                5000);
            }
          }
        },
        err => {
          this.counterClick = 5
          return false;
        });
    }
  }

  showMedecinForm() {
    this.isMedecin = true;
    this.isPatient = false;
  }

  showPatientForm() {
    this.isMedecin = false;
    this.isPatient = true;
  }
  ngAfterViewInit(): void {

    window.scrollTo(0, 0);
  }
  constructExternalLinkWithParams(link: string, params: { [key: string]: string }): string {
    const queryParams = new URLSearchParams(params).toString();
    return link + '?' + queryParams;
  }
  // validation code 

  onMedecinSubmitCode() {
    this.submitted = true;
    if (this.medecinFormCode.invalid) {
      return;
    }
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.http.post(_getURL(API_CONFIG.recaptchaverification), { token: token })
          .subscribe((response: any) => {
            if (response && response.success && response.score >= 0.5) {
              this.suivreLoginMedecinCode()
              return true;
            } else {
              this.isValid = false
              this.iziToast.show({
                message: "Erreur de recaptcha",
                messageColor: '#800f2f',
                titleColor: '#800f2f',
                progressBarColor: '#c9184a',
                icon: 'bi-exclamation-diamond',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#ff8fa3',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
                // overlay: true,
                // overlayClose: true,
              });
              setTimeout(() => {
                  this.isValid = true
                },
                5000);
              return false;
            }
            // Handle the verification response from the backend
          });

      }
    }, error => {
      this.isValid = false
      this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    });
  }
  suivreLoginMedecinCode(){
    if (this.medecinFormCode.value.codeValidation == undefined || this.medecinFormCode.value.codeValidation === '') {
      this.isValid = false
      this.iziToast.show({
        message: "Ajoutez votre code",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    }
    else {
      this.medecinService.authenticateMedecinNewVersionCode(this.medecinFormCode.value).subscribe((data: any) => {
          if (data && data.success && data.token && data.token != null && data.token != "null" && data.token != undefined && data.token != "undefined" && data.token.indexOf("Bearer") > -1 && data.type && data.type === 'tokenAuth') {
            data.token = data.token.replace("Bearer","redirection")
            let idclient =Array(15).fill(null).map(() => Math.random().toString(36).substr(2)).join('')
            var externalLink = othersLink+'/redirect';
            if(data.spec=== "Ophtalmologiste" || data.spec=== "Ophtalmologie"){
              externalLink=ophtalmoLink+'/redirect';
            }
            const queryParams = {
              redirect: data.token,
              clientID: idclient,
              redirect_url: "https://www.medivisto.com",
             };
            const urlWithParams = this.constructExternalLinkWithParams(externalLink, queryParams);
            window.location.href = urlWithParams;
            return true;
          } else {
            this.isValid = false
            let msg = ""
            if (data) {
              msg = data.msg
              this.iziToast.show({
                message: msg,
                messageColor: '#800f2f',
                titleColor: '#800f2f',
                progressBarColor: '#c9184a',
                icon: 'bi-exclamation-diamond',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#ff8fa3',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
                // overlay: true,
                // overlayClose: true,
              });
              setTimeout(() => {
                  this.isValid = true
                  this.router.navigate(['connexion']);
                },
                5000);
            }
          }
        },
        err => {
          return false;
        });
    }



  }
}
